import 'bootstrap';
//import '../scss/app.scss';

import '@fortawesome/fontawesome-free/js/all.js';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init({
    duration: 1500, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
});

//var $ = jQuery;
jQuery(document).ready(function(){

    $(function(){
        $('#header_nav').data('size','big');
    });

    $(window).scroll(function(){
        if($(document).scrollTop() > 0)
        {
            if($('#header_nav').data('size') == 'big')
            {
                $('#header_nav').removeClass( "big" ).addClass( "small" );
                $('#header_nav').data('size','small');
                $('#header_nav').stop().animate({
                    height:'7rem'
                },800);
            }
        }
        else
        {
            if($('#header_nav').data('size') == 'small')
            {
                $('#header_nav').removeClass( "small" ).addClass( "big" );
                $('#header_nav').data('size','big');
                $('#header_nav').stop().animate({
                    height:'9rem'
                },800);
            }
        }
    });

    $('.clickable-row').click(function() {
        const dataHref = $(this).data('href');
        if (dataHref) {
            window.document.location = dataHref;
        }
    });

    $('.clickable-row').hover(
        function(){ $(this).addClass('hover') },
        function(){ $(this).removeClass('hover') }
    )
});
